<template>
  <footer>
    <div class="container mx-auto">
      <p class="copyright">© {{ new Date().getFullYear() }} Logistic Dynamics, Inc.</p>
    </div>
  </footer>
</template>
<style scoped>
footer {
  margin-top: auto;
}
</style>
