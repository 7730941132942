import axios from "axios";

const dataProviderEnvVars = {
  async getVars() {
    console.log("fds");
    console.log(process.env.NODE_ENV);
    let apiurl =
      process.env.NODE_ENV === "production"
        ? "" + "config/env.json"
        : "/" + "config/env.json";
    console.log(apiurl);
    let result = await axios.get(apiurl);

    return result.data;
  },
};
export { dataProviderEnvVars };
